<template>
    <div>
        <el-dialog class="cus_dialog " title="保险详情" :visible.sync="dialogshow" :close-on-click-modal="false"
            @close="closeDialog" width="80%" height="90%">
            <el-table :data="DataList" row-key="id" border stripe>
                <el-table-column type="index" label="序号" width="55px" align="center"></el-table-column>
                <el-table-column label="姓名" prop="stu_name" width="100" align="center"></el-table-column>
                <el-table-column label="所属系部" prop="xibu_name" width="100" align="center"></el-table-column>
                <el-table-column label="所属班级" prop="class_name" width="100" align="center"></el-table-column>
                <el-table-column label="保险人员" prop="tch_name" width="100" align="center"></el-table-column>
                <el-table-column label="企业名称" prop="enter_name" width="150" align="center"></el-table-column>
                <el-table-column label="保险时间" prop="xf_date" width="160" align="center"></el-table-column>
                <el-table-column label="职位" prop="job" width="150" align="center"></el-table-column>
                <el-table-column label="保险情况" prop="xf_qingkuang" width="100" align="center"></el-table-column>
                <el-table-column label="保险地址" prop="xf_dizhi" width="150" align="center"></el-table-column>
                <el-table-column label="保险小结" prop="xf_xiaojie" min-width="150" align="center"></el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button @click="deleteRow(scope.row)" type="text" size="small">删除</el-button>
                    </template>
                </el-table-column>

            </el-table>

        </el-dialog>


    </div>
</template>

<script>

export default {
    props: ["baoxian"],
    components: {

    },
    data() {
        return {
            dialogshow: true,
            saveBtnShow: false,
            DataList: []
        }
    },
    mounted() {

        this.getList()
    },
    methods: {
        getList() {
            let baoxian = JSON.parse(JSON.stringify(this.baoxian))
            baoxian.keyword = baoxian.tch_name;
            baoxian.pagesize = 500
            this.$http.post("/api/baoxian_list", baoxian).then(res => {
                this.DataList = res.data.data
            })
        },
        closeDialog() {

            this.$$parent(this, "dialogShow", false)

        },
        deleteRow(e) {

            this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post("/api/baoxian_delete", { id: e.id }).then(res => {
                    this.getList()
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.$$parent(this,"getDataList")
                })
            }).catch(() => {

            });




        }



    }

}

</script>

<style scoped></style>